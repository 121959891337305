<template>
  <PageContainer
    :title="packageData.name"
    :back="actions.back"
    :submit="actions.submit"
  >
    <template v-slot:content>
      <!-- Package Card -->
      <div class="page-segment">
        <div class="page-segment-content">
          <PackageCard
            :serviceName="serviceName"
            :packageData="packageData"
            :show-button="false"
            :can-open-modal="false"
            :currency="packageData.service_price.currency"
            :icon-name="packageData.service_icon_name"
          />
        </div>
      </div>

      <!-- Package Description -->
      <div class="segment">
        <div class="segment-content">
          <div class="body">
            {{ packagePromotionText }}
          </div>
        </div>
      </div>

      <div class="page-segment">
        <div class="page-segment-content">
          <div class="heading-small margin-bottom margin-top-xs">
            {{ $translations.promos['packages']['value-with-pack'] }}
          </div>

          <!-- Full Package Card -->
          <PackageCard
            :serviceName="serviceName"
            :packageData="packageData"
            :currency="packageData.service_price.currency"
            :show-button="false"
            :show-full-card="true"
            :can-open-modal="false"
            title-size="2"
            :icon-name="packageData.service_icon_name"
          />

          <AddiBanner
            v-if="allowedInCountry('CO')"
            type="PACKAGE"
            :id="packageData.id"
            @clicked="navigateToCheckout('ADDI')"
          />
        </div>
      </div>

      <div class="segment">
        <div class="segment-content">
          <div class="small-tag padding-bottom-sm">
            {{ $translations.promos['packages'].generic.faq }}
          </div>

          <Accordion
            v-for="(question, key) in faq"
            :key="key"
            :text="question.title[lang]"
            :hasCustomContent="true"
            :is-collapsed="currentFaqIndex !== key"
            :custom-controller="true"
            @collapse="changeCurrentFaqIndex($event, key)"
          >
            <template v-slot:accordion>
              <div v-html="question.description[lang]"></div>
            </template>
          </Accordion>
        </div>
      </div>
    </template>
  </PageContainer>
</template>

<script>
import {
  PageContainer,
  Settings,
  Accordion,
  CurrencyFilter as currency
} from '@seliaco/red-panda'
import { PackageEvent, AddiEvent } from '@/types/events'
import AddiBanner from '@/components/banner/AddiBanner'
import countryValidation from '@/mixins/country-validation'

export default {
  name: 'PackageDetail',
  components: {
    PackageCard: () => import('./components/PackageCard'),
    AddiBanner,
    PageContainer,
    Accordion
  },
  mixins: [countryValidation],
  props: {
    title: String,
    serviceName: String,
    discount: Number,
    packageData: {},
    specialistId: String
  },
  data () {
    return {
      faq: {},
      faqLoading: false,
      currentFaqIndex: null,
      lang: '',
      actions: {
        back: {
          callback: () => {
            if (this.$route.query.back) {
              this.$router.replace({
                path: this.$route.query.back
              })
            } else {
              this.$router.go(-1)
            }
          }
        },
        submit: {
          text: this.$translations.promos.packages.buy,
          loading: false,
          callback: () => this.navigateToCheckout()
        }
      }
    }
  },
  mounted () {
    this.lang = this.$store.getters.language.lang
    this.getFAQ()
  },
  methods: {
    navigateToCheckout (additionalData) {
      this.$segment.track(AddiEvent.select_addibanner)
      let query = {
        back: this.$route.fullPath
      }

      if (additionalData) {
        query = {
          ...query,
          paymentMethodType: additionalData
        }
      }

      this.$router.push({
        name: 'CheckOut',
        params: {
          id: this.packageData.id,
          type: 'PACKAGE'
        },
        query
      })

      PackageEvent.selectPackage({
        user: this.$store.getters['auth/user'],
        detailsPackage: {
          title: this.serviceName,
          ...this.packageData,
          ...this.packageData.service_price
        }
      })
    },
    packageDataWithName () {
      const serviceName = this.serviceName
      return { ...this.packageData, serviceName }
    },
    getFAQ () {
      this.faqLoading = true
      Settings.read({
        columns: 'value',
        eq: {
          key: 'FAQ_GENERIC_PACKAGE_DETAIL'
        }
      })
        .then((response) => {
          if (response.data[0].value) {
            this.faq = JSON.parse(response.data[0].value)
          }
        })
        .finally(() => {
          this.faqLoading = false
        })
    },
    changeCurrentFaqIndex (value, key) {
      this.currentFaqIndex = value ? key : null
    }
  },
  computed: {
    packagePromotionText () {
      let text =
        this.$translations.promos.packages.generic['detail-description']

      text = text
        .replaceAll('{package_name}', this.packageData.name)
        .replaceAll('{discount}', `${this.packageData.discount}%`)
        .replaceAll('{service_name}', this.serviceName)

      return text
    }
  },
  filters: {
    currency
  }
}
</script>
